import React from "react";
import Prepare from "components/preparing/Prepare";


export default () => {
    return (
        <>
          <Prepare />
        </>
      );
    };
    